<template>
  <div>
    <Header />

    <div class="mt-10 px-4 sm:px-6 lg:px-8 lg:ml-10">
      <button class="popup-close" title="Zurück" @click="hauptmenu()">
        <img
          class="h-10 w-10"
          src="../../src/assets/return.png"
          alt="Workflow"
        />
      </button>
    </div>
    <div class="flex justify-center">
      <!-- Vertical menu -->
      <div
        class="menu w-full md:w-1/4 bg-white p-4 rounded-md shadow-lg border border-green-400"
      >
        <ul class="space-y-4">
          <li v-for="(menuItem, index) in menuItems" :key="index">
            <button
              @click="selectMenuItem(index)"
              :class="menuButtonClasses(index)"
            >
              <div class="flex items-center">
                <svg
                  v-if="index === 0"
                  class="w-6 h-6 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 14l9-5-9-5-9 5 9 5z" />
                  <path d="M12 14l9-5-9-5-9 5 9 5z" />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 7v10M12 14l9-5-9-5-9 5 9 5z"
                  />
                </svg>
                {{ menuItem }}
              </div>
            </button>
            <transition name="fade">
              <ul
                v-if="index === 2 && selectedItem === 2"
                class="pl-4 space-y-2"
              >
                <li
                  v-for="(subMenuItem, subIndex) in subMenuItems3"
                  :key="subIndex"
                >
                  <button
                    @click="selectSubMenuItem(index, subIndex)"
                    :class="subMenuButtonClasses(index, subIndex)"
                  >
                    {{ subMenuItem }}
                  </button>
                </li>
              </ul></transition
            >
            <transition name="fade">
              <ul
                v-if="index === 3 && selectedItem === 3"
                class="pl-4 space-y-2"
              >
                <li
                  v-for="(subMenuItem, subIndex) in subMenuItems4"
                  :key="subIndex"
                >
                  <button
                    @click="selectSubMenuItem(index, subIndex)"
                    :class="subMenuButtonClasses(index, subIndex)"
                  >
                    {{ subMenuItem }}
                  </button>
                </li>
              </ul></transition
            >
          </li>
        </ul>
      </div>

      <!-- Content section -->
      <div
        class="content w-full md:w-3/4 p-4 bg-white rounded-md shadow-lg border-l border-green-400"
      >
        <transition name="fade-slide">
          <p v-if="selectedItem === 0" class="text-lg">
            This is the case description content.
          </p>
          <p v-else-if="selectedItem === 1" class="text-lg">
            This is the Medical History content.
          </p>
         
            
              <p
                class="text-lg"
                v-if="selectedItem === 2 && selectedSubItem === 0"
              >
                This is non-apparatus examinations content.
              </p>
              <p
                class="text-lg"
                v-else-if="selectedItem === 2 && selectedSubItem === 1"
              >
                This is apparatus examinations content.
              </p>
              <p
                class="text-lg"
                v-else-if="selectedItem === 3 && selectedSubItem === 0"
              >
                This is blood test content.
              </p>
              <p
                class="text-lg"
                v-else-if="selectedItem === 3 && selectedSubItem === 1"
              >
                This is urine test content.
              </p>
              <p
                class="text-lg"
                v-else-if="selectedItem === 3 && selectedSubItem === 2"
              >
                This is stool test content.
              </p>
         
       
             <p class="text-lg" v-else-if="selectedItem === 4">This is the Doctor Referral content.</p>
             <p class="text-lg" v-else-if="selectedItem === 5">This is the Submission content.</p>
      
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/DefaultLayout.vue";
import { ref } from "vue";
import axios from "axios";
import { method } from "lodash";

export default {
  setup() {
    const selectedItem = ref(0);
    const selectedSubItem = ref(null);
    const menuItems = [
      "Fallbeschreibung",
      "Anamnese",
      "Untersuchen",
      "Laboranordungen",
      "Fachartz",
      "Falleinreichung",
    ];
    const subMenuItems3 = [
      "Nicht-Apparative ",
      "Apparative ",
    ];
    const subMenuItems4 = ["Blutennahme", "Urindiagnostik", "Stuhlprobe"];

    function selectMenuItem(index) {
      selectedItem.value = index;
      selectedSubItem.value = null;
    }
    function selectSubMenuItem(menuIndex, subIndex) {
      selectedItem.value = menuIndex;
      selectedSubItem.value = subIndex;
    }

    function menuButtonClasses(index) {
      return {
        "text-white hover:bg-red-600 hover:text-green-200":
          selectedItem.value === index,
          "text-white hover:bg-red-600 hover:text-gray-100":
          selectedItem.value !== index,
        "mb-2 px-4 py-2 rounded-md transition": true,
      };
    }
    function subMenuButtonClasses(menuIndex, subIndex) {
      return {
        "text-white hover:bg-red-600 hover:text-green-200":
          selectedSubItem.value === subIndex,
        "text-white hover:bg-red-600 hover:text-gray-100":
          selectedSubItem.value !== subIndex,
        "pl-4": true,
      };
    }
    return {
      selectedItem,
      selectedSubItem,
      menuItems,
      subMenuItems3,
      subMenuItems4,
      selectMenuItem,
      selectSubMenuItem,
      menuButtonClasses,
      subMenuButtonClasses,
    };
  },
  components: {
    Header,
  },
  data() {
    return {
      email: localStorage.email,
      menuOpen: false,
    };
  },
  created() {
    if (!localStorage.email) {
      this.$router.push("/login");
    }
  },
  methods: {
    hauptmenu() {
      this.$router.push("/casemodification");
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>

<style scoped>
.menu {
  max-width: 300px; /* Limit menu width for readability */
}

.menu button {
  border: none;

  width: 100%;
  text-align: left;
}

.content {
  padding: 1rem;
}
.fade-enter-active,
.fade-leave-active,
.fade-slide-enter-active,
.fade-slide-leave-active,
.fade-submenu-enter-active,
.fade-submenu-leave-active {
  transition: opacity 0.3s, transform 0.3s, height 0.3s;
}

.fade-enter,
.fade-slide-enter,
.fade-submenu-enter {
  opacity: 0;
  transform: translateY(-10px);
  height: 0;
}

.fade-leave-to,
.fade-slide-leave-to,
.fade-submenu-leave-to {
  opacity: 0;
  transform: translateY(10px);
  height: 0;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s, transform 0.3s, height 0.3s;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(10px);
  height: 0;
}

.bg-green-100 {
  background-color: #f0fff4;
}

.bg-white {
  background-color: #ffffff;
}

.text-green-700 {
  color: #047857;
}

.text-green-900 {
  color: #065f46;
}

.text-gray-700 {
  color: #4a5568;
}

.text-gray-900 {
  color: #2d3748;
}

.hover\:bg-green-200:hover {
  background-color: #6ee7b7;
}

.hover\:bg-gray-100:hover {
  background-color: #edf2f7;
}

.transition {
  transition: background-color 0.2s, color 0.2s;
}

.pl-4 {
  padding-left: 1rem;
}

/* Responsive styling */
@media (min-width: 768px) {
  .menu {
    max-width: 25%; /* Adjust menu width for tablets */
  }

  .content {
    padding: 2rem;
    max-width: 75%; /* Adjust content width for tablets */
  }
}

@media (min-width: 1024px) {
  .menu {
    max-width: 20%; /* Adjust menu width for laptops */
  }

  .content {
    padding: 3rem;
    max-width: 80%; /* Adjust content width for laptops */
  }
}
</style>
