<template>

  <router-view/>
</template>


<script>

import ButtonCompVue from './components/ButtonComp';






export default{
  name:'App',
  components:{
    ButtonCompVue
    
    
   
    // Option
  },
}


</script>

<style>
@import "@/style/main.scss";

</style>
