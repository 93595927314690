
<template>

  <div class="min-h-full sticky top-0" >
    <Disclosure as="nav" class="bg-emerald-600 sticky top-0" v-slot="{ open }">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link :to="{ name: 'Main' }">
              <img class="h-8 w-15" src="../../src/assets/Pafasi-dashboard.png" alt="Workflow" />
                </router-link>
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <router-link to="/main"><span class="bg-emerald-600 text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Hauptmenü</span></router-link>
               <router-link to="/warte"><span class="bg-emerald-600 text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Wartezimmer</span></router-link>
             
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              

              <!-- Profile dropdown -->
              <Menu as="div" class="ml-3 relative">
                <div>
                  <MenuButton class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span class="sr-only">Open user menu</span>
                    <img class="h-8 w-8 rounded-full" src= "../../src/assets/avatar.png" alt="" />
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="origin-top-right absolute top-0 ml-10  w-22 rounded-md shadow-lg  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                     <form action="" class="form" method="POST">
                     <MenuItem v-slot="{}">
                      <a
                        @click.prevent=onlogout();
                        :class="[
                          'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                        ]"
                        >Ausloggen</a
                      >
                    </MenuItem> 
                   </form> 
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <router-link to="/main"><span class="text-white">Hauptmenü</span></router-link>
        <router-link to="/warte"><span class="text-white">Wartezimmer</span></router-link>
        </div>
       
        <div class="pt-4 pb-3 border-t border-gray-700">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">{{ user.name }}</div>
              <div class="text-sm font-medium leading-none text-gray-400">{{ user.email }}</div>
            </div>
            
          </div>
          <div class="mt-3 px-2 space-y-1">
            <form action="" class="form" method="POST">
            <DisclosureButton
              as="a"
              @click.prevent=onlogout();
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 cursor-pointer"
              >Ausloggen
            </DisclosureButton>
            </form>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
<!-- 
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      
      </div>
    </header> -->
    <main>
      <div >
        
        <div class="px-4 py-3 sm:px-0">
          <!-- <div class="border-4 border-dashed border-gray-200 rounded-lg h-96" >
           
           </div> -->
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script >
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'
import { useRoute, useRouter } from 'vue-router';
import {computed} from 'vue';
import { useStore } from 'vuex';
import axios from "axios";
import Main from '@/views/MainPageView.vue'



 const navigation = [
   { name: 'Dashboard', to: {name:"Main"} },
   { name: "Wartezimmer", to: {name:"Wartezimmer"} } ,
   { name: "Anleitung",to: {name:"EinleitungView"} } ,
]

export default{ 
  name:"Headers",
   data() {
    return {
      User: {
        email: null,
        password: null
      },}},
   components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    MenuIcon,
    XIcon,
    Main
  },
  methods: {

      sendthesteps(){
    var data = new FormData();
     data.append("step","Der Benutzer hat sich bei der Plattform ausgeloggt.")
    data.append("onlineuser",localStorage.email);
axios
        .post(
          // "./Api/api.php?action=countervariable",
          "./Api/api.php?action=sendthesteps",
          data
        )
        .then(res => {
          if (res.data.error) {
            console.log("Error", res.data);
            alert(res.data.message);
          } else {
            console.log("Success", res.data.message);
           
          }
        })
        .catch(err => {
          console.log("Error", err);
        });
    },
    
    onlogout() {
      var data = new FormData();
      
     data.append("onlineuser", localStorage.email);
     
     localStorage.currentpage=='';
     localStorage.rrload=false;
    
      axios
        .post(
          // "./Api/api.php?action=logout",
          "./Api/api.php?action=logout",
          data
        )
        .then(res => {
          if (res.data.error) {
            console.log("Error", res.data);
            alert(res.data.message);
          } else {
            console.log("Success", res.data.message);
             localStorage.currentpage=='';
             this.sendthesteps();
             localStorage.email='';
            this.$router.push("/login");

          }
        })
        .catch(err => {
          console.log("Error", err);
        });
    }, 
  
setup() {
    const store = useStore();
    const router = useRouter();
    
  
    // store.dispatch("getUser");

    return {
      user: computed(() => store.state.user.data),
      navigation,
      logout,
    };
   
  }

  }}

 


</script>
