import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import './assets/tailwind.css' 
import Vuex from 'vuex'
import store from './store'
import { plugin, defaultConfig } from '@formkit/vue'


createApp(App).use(plugin, defaultConfig).use(router).use(Vuex).use(store).mount('#app')
